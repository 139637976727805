import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-multi-carousel/lib/styles.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import CommonLayout from "./layouts/CommonLayout";
import FallBack from "./componets/FallBack";
import ProtectedRoutes from "./route/ProtectedRoutes";
import DashboardLayout from "./layouts/DashboardLayout";
import ScrollToTop from "./componets/ScrollToTop";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./pages/Services"));
const Jobs = lazy(() => import("./pages/Jobs"));
const Blogs = lazy(() => import("./pages/Blogs"));
const SearchResults = lazy(() => import("./pages/SearchResult"));
const BlogDetail = lazy(() => import("./pages/BlogDetail"));
const CategoryDetail = lazy(() => import("./pages/CategoryDetail"));
const Partner = lazy(() => import("./pages/Partner"));
const Contact = lazy(() => import("./pages/ContactUs"));
const Carrier = lazy(() => import("./pages/BecomeCarrier"));
const AdminLogin = lazy(() => import("./pages/auth/Login"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
// const PaymentList = lazy(() => import("./pages/payments/List"));
// const StripePaymentsList = lazy(() =>
//   import("./pages/payments/StripePaymentsList")
// );
// const MakePayments = lazy(() => import("./pages/MakePayments"));
const Checkout = lazy(() => import("./pages/Checkout"));
const TrackShipment = lazy(() => import("./pages/TrackShipment"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));

function App() {
  <style>
    @import
    url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
  </style>;
  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<FallBack />}>
        <Routes>
          <Route element={<CommonLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/service" element={<Services />} />
            <Route path="/careers" element={<Jobs />} />
            <Route path="/blog" element={<Blogs />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/:slug" element={<BlogDetail />} />
            <Route path="/category/:slug" element={<CategoryDetail />} />
            <Route path="/tag/:slug" element={<CategoryDetail />} />
            <Route path="/why-partner-with-us" element={<Partner />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/carrier" element={<Carrier />} />
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/resetPassword/:token" element={<ResetPassword />} />
            {/* <Route path="/payment" element={<PaymentList />} /> */}
            {/* <Route path="/payments-list" element={<StripePaymentsList />} /> */}
            {/* <Route path="/shipper/make-payment" element={<MakePayments />} /> */}
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/tracking" element={<TrackShipment />} />

            {/* ..................ERROR PAGE............................ */}
            <Route path="not-found" element={<PageNotFound />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          {/*----------  Dashboard ----------------- */}
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoutes>
                <DashboardLayout />
              </ProtectedRoutes>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
